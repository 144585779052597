import { combineReducers } from "redux";
import sidebar from "./sidebarReducer";
import auth from "./authReducer";
import alert from "./alertReducer";
import provider from "./providerReducer";
import backstage from "./backstageReducer";
import user from "./userReducer";
import hd from "./HDReducer";
import aapanel from "./aapanelReducer";

export default combineReducers({
  sidebar,
  auth,
  alert,
  provider,
  backstage,
  user,
  hd,
  aapanel,
});
