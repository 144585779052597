const { PROVIDER_TYPES } = require("../types/providerTypes")

const initialState = {
  providersList: [],
  pageIndex: null,
  totalPages: null,
  pageSize: null,
  showAddModal: false,
  showDeleteModal: false,
  loading: false,
  search: {
    name: ''
  },
}

const providerReducer = (state = initialState, action) => {
  switch (action.type){
    case PROVIDER_TYPES.FETCH_PROVIDERS:

      const providersList = action.payload.data?.map((item, index) => {
        return {
          ...item,
          index: (action.payload.pageIndex - 1) * action.payload.pageSize + index + 1,
          createdBy: item.createdBy.username,
          updatedBy: item.updatedBy.username,
        }
      })

      return {
        ...state,
        providersList,
        pageIndex: action.payload.pageIndex,
        totalPages: action.payload.totalPages,
        pageSize: action.payload.pageSize,
      }
    case PROVIDER_TYPES.SHOW_ADD_MODAL:
      return { ...state, showAddModal: action.payload }
    case PROVIDER_TYPES.SHOW_DELETE_MODAL:
      return { ...state, showDeleteModal: action.payload }
    case PROVIDER_TYPES.LOADING:
      return { ...state, loading: action.payload.loading }
    case PROVIDER_TYPES.SEARCH:
      return { ...state, search: action.payload }
    default:
      return state
  }
}

export default providerReducer
