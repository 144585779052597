import React, { useState } from "react";
import {
  EditFilled,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PoweroffOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Modal, Switch, Avatar } from "antd";
import { getSetting } from "../../services/user";
import { toast } from "react-toastify";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilUser } from "@coreui/icons";

const HeaderMain = ({ collapsed, setCollapsed }) => {
  const [open, setOpen] = useState(false);

  const onChange = () => {
    setOtp_status(!otp_status);
  };

  const user = localStorage.getItem("user");
  const userLocal = JSON.parse(user);
  const [otp_status, setOtp_status] = useState(userLocal?.otp_enabled);

  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace("/login");
  };

  const OffOTP = async () => {
    const data = {
      id: userLocal.id,
      otp_status: otp_status,
    };
    try {
      const res = await getSetting(data);
      if (res) {
        toast.success("Lưu thành công!");
        setOpen(false);
        otp_status ? setOtp_status(true) : setOtp_status(false);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const ChangePass = () => {
    toast.success("chang pass!");
  };
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        <Button
          type="text"
          icon={
            collapsed ? (
              <MenuUnfoldOutlined style={{ fontSize: "32px" }} />
            ) : (
              <MenuFoldOutlined style={{ fontSize: "32px" }} />
            )
          }
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: "16px",
            width: 64,
            height: 64,
          }}
        />
      </div>
      <div className="flex justify-end mr-10 !border-none">
        <CDropdown className="text-black border-none hover:bg-none">
          <CDropdownToggle
            placement="bottom-end"
            className="py-0 text-black border-none "
            caret={false}
          >
            Xin chào: {userLocal?.fullname} &nbsp;
            <Avatar size="large" icon={<UserOutlined />} />
          </CDropdownToggle>
          <CDropdownMenu className="pt-0 hover:bg-none" placement="bottom-end">
            <CDropdownItem href="#">
              <Button
                type="primary"
                className="w-32 text-start"
                onClick={() => setOpen(true)}
              >
                <CIcon icon={cilUser} className="mr-1" />
                Thông tin
              </Button>
            </CDropdownItem>
            {/* <CDropdownItem
              onClick={ChangePass}
              className="cursor-pointer inline-block w-full"
            >
              <Button type="primary" className="w-32 text-start">
                <EditFilled className="-translate-y-1/4" />
                Đổi Password
              </Button>
            </CDropdownItem> */}
            <CDropdownItem
              onClick={logout}
              className="cursor-pointer inline-block w-full"
            >
              <Button type="primary" className="w-32 text-start">
                <PoweroffOutlined className="-translate-y-1/4" />
                Đăng xuất
              </Button>
            </CDropdownItem>
          </CDropdownMenu>
          <Modal
            footer={""}
            centered
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
          >
            <div>
              <div className="flex justify-between items-center w-full">
                {" "}
                <h2 className="text-3xl pb-3">Thông tin</h2>{" "}
                <span
                  onClick={() => setOpen(false)}
                  className="text-2xl w-10 h-10 text-center absolute right-0 top-0 cursor-pointer px-2 py-2 hover:text-[#9c67ff] font-bold"
                >
                  X
                </span>
              </div>
              <hr className="py-2" />

              <div className="flex flex-col gap-3 pb-3 text-xl">
                <div>
                  <span>Tài khoản: </span> <span>{userLocal?.username}</span>
                </div>
                <div>
                  <span>Tên đầy đủ: </span> <span>{userLocal?.fullname}</span>
                </div>
                {/* <div>
              <div>
                <span>otpBase32: </span> <span>{infoUsers?.otpBase32}</span>
              </div>
            </div> */}
              </div>
            </div>
            <div className="flex ">
              <h5 className="mr-3">Xác thực OTP: </h5>
              <Switch
                className="border"
                defaultChecked={userLocal?.otp_enabled}
                onChange={onChange}
              />
            </div>
            <Button className="w-full mt-2" onClick={OffOTP}>
              Lưu
            </Button>
          </Modal>
        </CDropdown>
      </div>
    </div>
  );
};

export default HeaderMain;
