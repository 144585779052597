import React from "react";
import vpsManage from './vpsRouter'

const Home = React.lazy(() => import("../views/home"))

const routes = [
  { path: "/", exact: true, name: "Trang chủ"},
  { path: '/trang-chu', name: 'Trang chủ', element: Home },
  ...vpsManage,
];

export default routes;
