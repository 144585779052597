import { makeRequest } from "./index";
const handleParams = (params) => {
  const paramKeys = [];
  if (params) {
    Object.keys(params).map((key) => {
      paramKeys.push(key + "=" + params[key]);
      return paramKeys;
    });

    return paramKeys && paramKeys.length ? paramKeys.join("&") : "";
  }
};

export const getPagingUser = (params) => {
  const url = `/api/user/getPaging?${handleParams(params)}`;
  return makeRequest({ url, method: "GET" });
};

export const updateUser = (id, data) => {
  const url = `/api/user/update/${id}`;
  return makeRequest({ url, method: "PUT", data });
};
export const deleteUser = (id) => {
  const url = `/api/user/delete/${id}`;
  return makeRequest({ url, method: "DELETE" });
};
export const UserLogin = (data) => {
  const url = `api/login/`;
  return makeRequest({ url, method: "POST", data });
};

export const generateOTP = (id) => {
  const url = `api/otp/generate/`;
  const data = { id };
  return makeRequest({ url, method: "POST", data });
};

export const getSetting = (data) => {
  const url = `api/otp/setting`;
  return makeRequest({ url, method: "POST", data });
};
