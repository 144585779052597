import React from "react";
import {
  UserOutlined,
  HomeOutlined,
  GroupOutlined,
  GlobalOutlined,
  DashboardOutlined,
  ApiOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Layout } from "antd";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "react-pro-sidebar";
import logo from "../../assets/images/logo.jpg";

const { Sider } = Layout;

const Sidebar = ({ collapsed }) => {
  const navigate = useNavigate();

  const handlePage = (href) => {
    navigate(href);
  };

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={230}
      style={{
        position: "fixed",
        left: 0,
        height: "100vh",
      }}
    >
      <div className="logo-vertical p-3">
        <a href={"/"}>
          <img src={logo} alt="" />
        </a>
      </div>
      <Menu className="">
        <MenuItem
          className="custom-menu font-medium text-[15px] "
          active={window.location.pathname === "/trang-chu"}
          icon={<HomeOutlined />}
          onClick={() => handlePage("/")}
        >
          Trang chủ
        </MenuItem>
        <MenuItem
          className="custom-menu font-medium text-[15px]"
          active={window.location.pathname === "/manage_vps/vps"}
          icon={<GroupOutlined />}
          onClick={() => handlePage("/manage_vps/vps")}
        >
          Quản lý VPS
        </MenuItem>
        <MenuItem
          className="custom-menu font-medium text-[15px]"
          active={window.location.pathname === "/manage_vps/domain"}
          icon={<GlobalOutlined />}
          onClick={() => handlePage("/manage_vps/domain")}
        >
          Danh sách Domain
        </MenuItem>
        <MenuItem
          className="custom-menu font-medium text-[15px]"
          active={window.location.pathname === "/manage_vps/manage-backstage"}
          icon={<UsergroupAddOutlined />}
          onClick={() => handlePage("/manage_vps/manage-backstage")}
        >
          Bộ phận sử dụng
        </MenuItem>
        <MenuItem
          className="custom-menu font-medium text-[15px] hover:!bg-black"
          active={window.location.pathname === "/manage_vps/manage-provider"}
          icon={<ApiOutlined />}
          onClick={() => handlePage("/manage_vps/manage-provider")}
        >
          Đối tác VPS
        </MenuItem>

        <MenuItem
          className="custom-menu font-medium text-[15px]"
          active={window.location.pathname === "/manage_vps/manage-user"}
          icon={<DashboardOutlined />}
          onClick={() => handlePage("/manage_vps/manage-user")}
        >
          Quản trị viên
        </MenuItem>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
