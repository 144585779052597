import React from "react";
import { Button, Form, Input, Modal, QRCode, Space } from "antd";
import { UserLogin, generateOTP } from "src/services/user";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import PinInput from "react-pin-input";

const Login = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [idUser, setIdUser] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [text, setText] = useState("");
  const [ggKey, setGgKey] = useState("");
  const [verify, setVerify] = useState(false);
  const [form] = Form.useForm();
  const [formLogin] = Form.useForm();
  const [optCode, setOptCode] = useState("");

  const onFinish = async (data) => {
    console.log("fomr login", data.username);
    try {
      const res = await UserLogin(data);
      if (res.status === 2) {
        toast.warning("Mật khẩu không đúng!");
        return;
      }
      if (res.status === 4) {
        toast.warning("Tài khoản không đúng");
        return;
      }
      if (res.status === 1) {
        console.log("user login ok", res);
        if (!res.data?.user.otp_enabled && res.data?.user.otp_verified) {
          window.location.replace("/");
          toast.success("Đăng nhập thành công.");
          localStorage.setItem("user", JSON.stringify(res?.data?.user));
          localStorage.setItem("token", res?.data?.user?.jwt);
        } else {
          setIsModalOpen(true);
          if (!res.data?.user.otp_verified) {
            const otp = await generateOTP(res.data.user.id);
            setGgKey(otp.base32);
            setText(otp.otpauth_url);
            setVerify(true);
          }
        }
      }
      setIdUser(res?.data?.user.id);
    } catch (error) {
      console.log(error);
    }
  };

  const userVerify = async () => {
    try {
      const res = await axios.post(`${API_URL}/api/otp/verify/`, {
        id: idUser,
        token: optCode,
      });
      if (res.status === 200) {
        localStorage.setItem("user", JSON.stringify(res?.data?.user));
        localStorage.setItem("token", res?.data?.jwt);
        setIsModalOpen(false);
        window.location.replace("/");
        toast.success("Xác thực thành công");
        setOptCode("");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const userValidate = async () => {
    try {
      const res = await axios.post(`${API_URL}/api/otp/validate/`, {
        id: idUser,
        token: optCode,
      });

      if (res) {
        const token = localStorage.getItem("token");
        if (!token) {
          localStorage.setItem("token", res.data.jwt);
          localStorage.setItem("user", JSON.stringify(res?.data?.user));
        } else if (token) {
          window.location.replace("/");
          setOptCode("");
          toast.success("Đăng nhập thành công.");
        }
      }
    } catch (error) {
      if (error.code === "ERR_BAD_REQUEST") {
        toast.error("OTP không đúng hoặc hết thời hạn.");
      }
    }
  };

  return (
    <div className="w-full flex justify-center items-center top-1/2 fixed -translate-y-1/2">
      <Modal open={isModalOpen} footer={""}>
        <Space direction="vertical" align="center" className="text-center">
          <h4 className="text-center">
            {verify
              ? "Quét mã QR để lấy mã otp để xác thực"
              : "Nhập mã OTP để xác nhận."}
          </h4>
          <QRCode value={text} />
          <h3>{ggKey}</h3>
        </Space>
        <Space>
          <PinInput
            length={6}
            type="numeric"
            inputMode="number"
            style={{ padding: "10px" }}
            inputStyle={{ borderColor: "red" }}
            inputFocusStyle={{ borderColor: "blue" }}
            onComplete={(value) => {
              setOptCode(value);
            }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
          <Form name="veryfy" form={form} className="">
            <div className="flex flex-col"></div>
            <Form.Item>
              <Button
                type="primary"
                onClick={!verify ? userValidate : userVerify}
              >
                Xác nhận
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Modal>

      <Form
        form={formLogin}
        name="formLogin"
        className="border rounded-xl px-5"
        size="large"
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 800,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <h2 className="uppercase py-4 text-center">Đăng nhập</h2>
        <hr className="pb-4" />
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input className="w-[350px]" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password className="w-[350px]" />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Đăng nhập
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default Login;
