import React from "react";
import ReactDOM from "react-dom/client";
import "./scss/style.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "core-js";
import DataProvider from "./redux/store";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <DataProvider>
    <App />
  </DataProvider>
);

reportWebVitals();
