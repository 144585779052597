const { USER_MANAGEMENT_TYPES } = require("../types/userTypes")

const initialState = {
  usersList: [],
  pageIndex: null,
  totalPages: null,
  pageSize: null,
  showAddModal: false,
  showDeleteModal: false,
  loading: false,
  search: {
    name: ''
  },
}

const userReducer = (state = initialState, action) => {
  switch (action.type){
    case USER_MANAGEMENT_TYPES.FETCH_USERS_MANAGEMENT:

      const usersList = action.payload.data?.map((item, index) => {
        return {
          ...item,
          index: (action.payload.pageIndex - 1) * action.payload.pageSize + index + 1,
          role: item.role.name
        }
      })

      return {
        ...state,
        usersList,
        pageIndex: action.payload.pageIndex,
        totalPages: action.payload.totalPages,
        pageSize: action.payload.pageSize,
      }
    case USER_MANAGEMENT_TYPES.SHOW_ADD_MODAL:
      return { ...state, showAddModal: action.payload }
    case USER_MANAGEMENT_TYPES.SHOW_DELETE_MODAL:
      return { ...state, showDeleteModal: action.payload }
    case USER_MANAGEMENT_TYPES.LOADING:
      return { ...state, loading: action.payload.loading }
    case USER_MANAGEMENT_TYPES.SEARCH:
      return { ...state, search: action.payload }
    default:
      return state
  }
}

export default userReducer
