import React from "react";

const ManageProvider = React.lazy(() =>
  import("../views/manage-vps/manage-provider")
);
const vps = React.lazy(() => import("../views/manage-vps/vps"));

const ManageBackstage = React.lazy(() =>
  import("../views/manage-vps/manage-backstage")
);
const Domain = React.lazy(() => import("../views/manage-vps/domain"));
const ManageUser = React.lazy(() => import("../views/manage-vps/manage-user"));

const vpsRouter = [
  { path: "/manage_vps", name: "Quản lý VPS", element: vps, exact: true },
  {
    path: "/manage_vps/manage-provider",
    name: "Đối tác VPS",
    element: ManageProvider,
  },
  { path: "/manage_vps/vps", name: "Quản lý VPS", element: vps },
  {
    path: "/manage_vps/manage-backstage",
    name: "Bộ phận sử dụng",
    element: ManageBackstage,
  },
  { path: "/manage_vps/domain", name: "Domain", element: Domain },
  {
    path: "/manage_vps/manage-user",
    name: "Quản trị viên",
    element: ManageUser,
  },
];

export default vpsRouter;
