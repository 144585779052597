import axios from "axios";
import { message } from "antd";
import { getToken } from "../utils/index";

const https = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    post: {
      "Content-Type": "application/json",
    },
  },
});

https.defaults.headers.post["Content-Type"] = "application/json";

const token = getToken();

if (token) https.defaults.headers.common["Authorization"] = "Bearer " + token;

export const makeRequest = async (config) => {
  try {
    const response = await https.request(config);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error;
    } else {
      return error;
    }
  }
};

https.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    if (error.response.data === "Forbidden") {
      console.warn("Bạn không có quyền truy cập!");
      window.location.replace("/login");
    } else if (error.response.data.data.name === "TokenExpiredError") {
      message.error("Làm ơn đăng nhập để tiếp tục!");
      setTimeout(() => {
        window.location.replace("/login");
      }, 3000);
    }
    return Promise.reject(error);
  }
);

export const setAuthorization = (token) => {
  https.defaults.headers.common["Authorization"] = "Bearer " + token;
};
