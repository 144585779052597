export const sessionKey = Object.freeze({
    TOKEN: "token",
    USER_ID: "userId",
  });
  
  const getToken = () => {
    const token = localStorage.getItem(sessionKey.TOKEN);
    return token;
  };
  const setToken = (token) => {
    localStorage.setItem(sessionKey.TOKEN, token);
  };
  const setUserId = (id) => {
    localStorage.setItem(sessionKey.USER_ID, JSON.stringify(id));
  };
  const getUserId = () => {
    const userId = JSON.parse(localStorage.getItem(sessionKey.USER_ID));
    return userId;
  };
  
  export { setUserId, getToken, setToken, getUserId };
       