// import { HashRouter, Routes, Route } from "react-router-dom";
// import React, { Suspense, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import Login from "./views/login";
// import { ToastContainer } from "react-toastify";
// import { getToken, getUserId } from "./utils";
// import { getUserAuth } from "./redux/actions/authAction";
// import PrivateRoute from "./router/PrivateRouter";

// const loading = (
//   <div className="pt-3 text-center">
//     <div className="sk-spinner sk-spinner-pulse"></div>
//   </div>
// );

// // Containers
// const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// // Pages
// const Page404 = React.lazy(() => import("./views/Page404"));
// const Page500 = React.lazy(() => import("./views/Page500"));
// function App() {
//   const { auth } = useSelector((state) => state);

//   const dispatch = useDispatch();

//   useEffect(() => {
//     if (getToken()) {
//       dispatch(getUserAuth({ token: getToken(), userId: getUserId() }));
//     }
//   }, [dispatch]);

//   return (
//     // <HashRouter>
//     //   <ToastContainer />
//     //   <Suspense>
//     //      <Routes>
//     //       <Route
//     //         exact
//     //         path="/*"
//     //         name="Home"
//     //         element={<DefaultLayout />}
//     //       />
//     //       <Route exact path="/login" name="Login Page" element={<Login />} />
//     //       <Route exact path="/404" name="Page 404" element={<Page404 />} />
//     //       <Route exact path="/500" name="Page 500" element={<Page500 />} />
//     //     </Routes>

//     //   </Suspense>
//     // </HashRouter>
//     <Routes>
//       <Route path="/login" element={<Login />} />
//       <Route path="/*" exact name="Home" element={<PrivateRoute element={<DefaultLayout />} />} />
//       <Route exact path="/404" name="Page 404" element={<Page404 />} />
//       <Route exact path="/500" name="Page 500" element={<Page500 />} />
//     </Routes>
//   );
// }

// export default App;

import "./App.css";
import Login from "./views/login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layouts from "./components/Layouts";
import { AppContent } from "./components";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <div>
        {" "}
        <Router>
          <ToastContainer autoClose={2000} />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/*" element={<MainLayout />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}
const MainLayout = () => {
  return (
    <Layouts>
      <AppContent />
    </Layouts>
  );
};

export default App;
