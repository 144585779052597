import React from "react";
import CIcon from "@coreui/icons-react";
import { cilPuzzle, cilSpeedometer } from "@coreui/icons";
import { CNavGroup, CNavItem } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Trang chủ",
    to: "/",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: "info",
      text: "Home",
    },
  },
  {
    component: CNavGroup,
    name: "Quản lý VPS",
    to: "/manage_vps",
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Quản lý VPS",
        to: "/manage_vps/vps",
      },
      {
        component: CNavItem,
        name: "Danh sách Domain",
        to: "/manage_vps/domain",
      },
      {
        component: CNavItem,
        name: "Bộ phận sử dụng",
        to: "/manage_vps/manage-backstage",
      },
      {
        component: CNavItem,
        name: "Đối tác VPS",
        to: "/manage_vps/manage-provider",
      },

      {
        component: CNavItem,
        name: "Quản trị viên",
        to: "/manage_vps/manage-user",
      },
      // {
      //   component: CNavItem,
      //   name: "Danh sách Aapanel",
      //   to: "/manage_vps/manage-aapanel",
      // },
    ],
  },
];

export default _nav;
