const { HD_TYPES } = require("../types/HDTypes");

const initialState = {
  HDList: [],
  pageIndex: null,
  totalPages: null,
  pageSize: null,
  showAddModal: false,
  showDeleteModal: false,
  loading: false,
  search: {
    name: "",
  },
};

const HDReducer = (state = initialState, action) => {
  switch (action.type) {
    case HD_TYPES.FETCH_HD:
      const HDList = action.payload.data?.map((item, index) => {
        return {
          ...item,
          index:
            (action.payload.pageIndex - 1) * action.payload.pageSize +
            index +
            1,
          createdBy: item.createdBy.username,
          updatedBy: item.updatedBy.username,
        };
      });

      return {
        ...state,
        HDList,
        pageIndex: action.payload.pageIndex,
        totalPages: action.payload.totalPages,
        pageSize: action.payload.pageSize,
      };
    case HD_TYPES.SHOW_ADD_MODAL:
      return { ...state, showAddModal: action.payload };
    case HD_TYPES.SHOW_DELETE_MODAL:
      return { ...state, showDeleteModal: action.payload };
    case HD_TYPES.LOADING:
      return { ...state, loading: action.payload.loading };
    case HD_TYPES.SEARCH:
      return { ...state, search: action.payload };
    default:
      return state;
  }
};

export default HDReducer;
